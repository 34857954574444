@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

* {
  box-sizing: border-box;
  font-size: 16px;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100% !important;
  height: 100%;
}

#root {
  height: 100%;
}

.mapboxgl-control-container {
  display: none;
}

p {
  margin: 0;
}
