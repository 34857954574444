.marker-position {
    position: absolute;
    top: 20px;
    left: 20px;
    background-image: url('/src/media/location-pin.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);

}
